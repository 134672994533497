export default function getAdaptedContent(content) {
    if (!content) {
        return undefined;
    }

    const { leftNavigationLinks, rightNavigationLinks, menuLinks, withAnimationBorder } = content;

    const getAdaptedLink = item => ({
        label: item?.fields?.text,
        link: item?.fields?.url,
        icon: item?.fields?.image?.fields?.file?.url
    });

    const getAdaptedRelatedLinks = item => ({
        label: item?.fields?.text,
        icon: item?.fields?.image?.fields?.file?.url,
        dropdownLinks: item?.fields?.relatedItems?.map(i => getAdaptedLink(i))
    });

    const getAdaptedLinks = item => {
        if (item?.fields?.relatedItems) {
            return getAdaptedRelatedLinks(item);
        }

        return getAdaptedLink(item);
    };

    const navigationLinks =
        leftNavigationLinks && rightNavigationLinks ?
            {
                left: leftNavigationLinks.map(getAdaptedLinks),
                right: rightNavigationLinks.map(getAdaptedLinks)
            } :
            undefined;

    return {
        navigationLinks,
        menuItems: menuLinks ? menuLinks.map(getAdaptedLinks) : undefined,
        withAnimationBorder
    };
}
