// TODO: Cover by tests @Anton
export default function getAdaptedTariffCalculationFormContent(data) {
    if (!data) {
        return null;
    }

    return {
        inputBackground: data.fields?.config?.inputBackground,
        tariffFormMode: data?.fields?.formMode,
        // TODO: Looks wrong, please check @Anton
        labels: data?.fields,
        consumptionOptions: data?.fields?.consumptionOptions,
        consumptionIconsType: data?.fields?.consumptionIconsType,
        formType: data?.fields?.formType,
        id: data.sys?.id,
        validationConfig: {
            minimalElectricityConsumption: data?.fields?.minimalElectricityConsumption,
            minimalGasConsumption: data?.fields?.minimalGasConsumption
        }
    };
}
