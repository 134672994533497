import getAdaptedTariffCalculationFormContent from './tariffCalculationForm';
import getAdaptedHeaderContent from './header';
import getContractData from './contract';

import { SECTION_NAMES, FORM_NAMES } from '../../../constants';

// TODO: Rename to getAdaptedBlockModelContentByField
// TODO: Need to move to a separate file
function getContentfulDataByField(fieldType, data) {
    switch (fieldType) {
        case 'element': {
            const id = data.sys?.id;
            const { fields } = data;

            return { id, fields };
        }
        case 'link': {
            const type = data.sys?.contentType?.sys?.id;
            const id = data.sys?.id;
            const { fields } = data;

            return { type, id, fields };
        }
        case 'block':
        case 'text':
        case 'animation':
        case 'templates': {
            return data;
        }
        case 'picture': {
            const { imageAlt, imageTitle, config } = data;
            const imageSrcset = {
                mobileImageSrc: data.mobileImageSrc?.fields?.file?.url,
                tabletImageSrc: data.tabletImageSrc?.fields?.file?.url,
                desktopImageSrc: data.desktopImageSrc?.fields?.file?.url
            };
            const webPImageSrcset = {
                mobileWebPImageSrc: data.mobileWebPImageSrc?.fields?.file?.url,
                tabletWebPImageSrc: data.tabletWebPImageSrc?.fields?.file?.url,
                desktopWebPImageSrc: data.desktopWebPImageSrc?.fields?.file?.url
            };

            return { imageSrcset, webPImageSrcset, imageAlt, imageTitle, config };
        }
        case 'button': {
            const { type, content, link, colorPattern, classes, styleOptionsBool = [] } = data;
            const withBorder = styleOptionsBool.includes('bordered');
            const withShadow = styleOptionsBool.includes('shadow');
            const animated = styleOptionsBool.includes('animated');
            const styleOptions = { colorPattern, withBorder, withShadow, animated };
            return { type, content, link, classes, styleOptions };
        }
        case 'video': {
            const { featuredVideo, featuredVideo2 } = data;
            return { introVideos: featuredVideo, loopVideos: featuredVideo2 };
        }
        default:
            return null;
    }
}

// TODO: cover by tests
function getSectionData(sectionType, data) {
    const {
        sys,
        fields,
        fields: { relatedItems, modification, sectionClassName, background, sectionId, additionalData, customCss }
    } = data;

    switch (sectionType) {
        // TODO: Need to move to a separate file
        case SECTION_NAMES.PRESENTATION: {
            const pictureData = relatedItems.find(block => block.sys?.contentType?.sys?.id === 'picture');
            const animationData = relatedItems.find(block => block.sys?.contentType?.sys?.id === 'animation');
            const videoData = relatedItems.find(block => block.sys?.contentType?.sys?.id === 'mediaPost');
            const contentData = relatedItems.find(block => block.sys?.contentType?.sys?.id === 'flexText');
            const badgeData = relatedItems.find(block => block.sys?.contentType?.sys?.id === 'link');
            const visualPosition = parseContentfulData.getVisualPosition(modification);
            const contentPosition = parseContentfulData.getContentPosition(modification);

            const getVisualProps = () => {
                let visualProps;
                let visualType;

                if (animationData) {
                    visualProps = getContentfulDataByField('animation', animationData?.fields);
                    visualType = 'animation';
                }
                if (pictureData) {
                    visualProps = getContentfulDataByField('picture', pictureData?.fields);
                    visualType = 'picture';
                }
                if (videoData) {
                    visualProps = getContentfulDataByField('video', videoData?.fields);
                    visualType = 'video';
                }

                return { ...visualProps, visualType };
            };

            const badge = badgeData ?
                {
                    image: badgeData.fields?.image?.fields?.file?.url,
                    href: badgeData.fields?.url
                } :
                undefined;

            return {
                key: sys?.id,
                className: sectionClassName,
                visualProps: getVisualProps(),
                content: contentData.fields.content,
                visualPosition,
                contentPosition,
                backgroundColor: background,
                id: sectionId,
                badge,
                customCss
            };
        }

        case SECTION_NAMES.PRODUCT_ADVANTAGES: {
            const listOfAdvantages = additionalData?.contractAdvantages;

            return { id: sys?.id, sectionClassName, listOfAdvantages };
        }

        case SECTION_NAMES.SEASONAL: {
            return {
                id: sys?.id,
                buttonLink: fields?.buttonLink,
                buttonTitle: fields?.buttonTitle,
                buttonColor: fields?.buttonColor,
                customCss: fields?.customCss,
                header: fields?.header,
                paragraph: fields?.paragraph,
                mobileImageUrl: fields?.mobileImage?.fields?.file.url,
                imageTitle: fields?.image?.fields?.title,
                imageDescription: fields?.image?.fields?.description,
                imageUrl: fields?.image?.fields?.file?.url,
                sectionClassName
            };
        }

        case SECTION_NAMES.CREDENTIALS: {
            return {
                id: sys?.id,
                newsQuotes: fields?.newsQuotes || []
            };
        }

        case SECTION_NAMES.TARIFF_ADVANTAGE_POINT: {
            return {
                id: sys?.id,
                header: fields?.header,
                subHeader: fields?.subHeader,
                sectionId: fields?.sectionId,
                buttonTitle: fields?.buttonTitle
            };
        }

        case SECTION_NAMES.FEATURED_PRODUCERS: {
            return {
                id: sys?.id,
                title: fields?.title,
                summary: fields?.summary,
                listTitle: fields?.listTitle,
                featuredProducers: fields?.featuredProducers || [],
                labels: fields?.labels,
                allProducersLink: fields?.allProducersLink,
                producerLink: fields?.producerLink || '',
                sectionClassName: fields?.sectionClassName
            };
        }

        case SECTION_NAMES.FAQ: {
            return {
                id: sys?.id,
                header: fields?.header,
                additionalData: fields?.additionalData
            };
        }

        case SECTION_NAMES.FINANCIAL_ADVISOR: {
            return {
                id: sys?.id,
                sectionClassName,
                relatedItemSys: fields?.relatedItem?.sys,
                relatedItemFields: fields?.relatedItem?.fields,
                formType: fields?.relatedItem?.fields?.type,
                sectionHeader: additionalData?.section?.sectionHeader,
                sectionSubHeader: additionalData?.section?.sectionSubHeader,
                primaryTextColor: additionalData?.section?.primaryTextColor,
                backgroundImageUrl: additionalData?.section?.backgroundImageUrl,
                customCss: fields?.customCss
            };
        }

        case FORM_NAMES.SALES_AGENT_FORM: {
            return {
                id: sys?.id,
                formData: fields?.additionalData?.formData
            };
        }

        case SECTION_NAMES.BONUS_CARDS: {
            return {
                id: sys?.id,
                additionalData: fields?.additionalData,
                header: fields?.header,
                paragraph: fields?.paragraph
            };
        }

        case SECTION_NAMES.SEPARATED_BLOCKS: {
            return {
                id: sys?.id,
                imageTitle: fields?.imageTitle,
                imageTitle2: fields?.imageTitle2,
                imageSubTitle: fields?.imageSubTitle,
                imageSubTitle2: fields?.imageSubTitle2,
                image: fields?.image?.fields,
                image2: fields?.image2?.fields
            };
        }

        case SECTION_NAMES.DARK_STANDARD: {
            return {
                id: sys?.id,
                buttonTitle: fields?.buttonTitle,
                buttonLink: fields?.buttonLink,
                paragraph: fields?.paragraph,
                header: fields?.header,
                sectionClassName: fields?.sectionClassName,
                imageSubTitle: fields?.imageSubTitle,
                image: fields?.image?.fields
            };
        }

        case SECTION_NAMES.ADVANTAGES: {
            return {
                id: sys?.id,
                header: fields?.header,
                image: fields?.bannerImage?.fields?.file?.url,
                backgroundOpacity: fields?.backgroundOpacity,
                points: fields?.points
            };
        }

        case SECTION_NAMES.TOP_INDEX: {
            const id = sys?.id;
            const { mediaPosts, showTabs } = fields?.relatedItem?.fields;

            const slideshowItems = mediaPosts
                .filter(m => m.fields)
                .map(mediaPost => ({
                    id: mediaPost?.sys?.id,
                    label: mediaPost?.fields?.title,
                    backgroundMedia: {
                        introVideos: mediaPost?.fields?.featuredVideo,
                        loopVideos: mediaPost?.fields?.featuredVideo2
                    },
                    backgroundColor: mediaPost?.fields?.color,
                    header: mediaPost?.fields?.header,
                    summary: mediaPost?.fields?.summary,
                    duration: mediaPost?.fields?.slideDuration,
                    url: mediaPost?.fields?.url
                }));

            return {
                id,
                sectionId,
                relatedItems: relatedItems || [],
                slideshowItems,
                showTabs
            };
        }

        case SECTION_NAMES.PARALLAX: {
            return {
                id: sys?.id,
                background: fields?.background,
                buttonTitle: fields?.buttonTitle,
                buttonLink: fields?.buttonLink,
                paragraph: fields?.paragraph,
                header: fields?.header,
                image1: fields?.image?.fields,
                image2: fields?.image2?.fields,
                image3: fields?.image3?.fields,
                subHeader: fields?.subHeader,
                subHeaderDescription: fields?.subHeaderDescription,
                subTitleLink: fields?.subTitleLink,
                sectionClassName: fields?.sectionClassName,
                customCss: fields?.customCss
            };
        }

        case SECTION_NAMES.STANDARD_MEDIA: {
            return {
                id: sys?.id,
                image: fields?.image?.fields,
                imagePosition: fields?.imagePosition,
                video: fields?.video,
                video2: fields?.video2,
                header: fields?.header,
                paragraph: fields?.paragraph,
                buttonTitle: fields?.buttonTitle,
                buttonColor: fields?.buttonColor,
                buttonLink: fields?.buttonLink,
                background: fields?.background,
                sectionClassName: fields?.sectionClassName
            };
        }

        case SECTION_NAMES.BLOCKQUOTE: {
            return {
                id: sys?.id,
                paragraph: fields?.paragraph,
                subHeader: fields?.subHeader,
                sectionClassName: fields?.sectionClassName,
                image: fields?.image?.fields
            };
        }

        case SECTION_NAMES.TWO_COLUMN_ARTICLE: {
            return {
                id: sys?.id,
                header: fields?.header,
                position: fields?.position,
                imagePosition: fields?.imagePosition,
                image: fields?.image?.fields,
                image2: fields?.image2?.fields,
                paragraph: fields?.paragraph,
                paragraph2: fields?.paragraph2,
                article: fields?.article,
                article2: fields?.article2,
                sectionClassName: fields?.sectionClassName,
                customCss: fields?.customCss
            };
        }

        case SECTION_NAMES.PICTURE: {
            return {
                id: sys?.id,
                image: fields?.image?.fields,
                customCss: fields?.customCss
            };
        }

        default:
            return null;
    }
}

function getNewsQuotesData(data) {
    const { fields } = data;

    return {
        imageSource: fields?.logo?.fields?.file?.url,
        imageAltText: fields?.logo?.fields?.title,
        content: fields?.content,
        hyperlink: fields?.link
    };
}

// TODO: Cover by tests
function getTariffModalConfig(data) {
    const tariffModalData = data?.producersContent?.fields?.data?.tariffModal ?? {};

    const { agbUrl, tariffAdvantages } = tariffModalData;

    return { agbUrl, tariffAdvantages };
}

export const parseContentfulData = {
    getVisualPosition,
    getContentPosition
};

// TODO: Cover by tests
function getVisualPosition(string) {
    const picturePart = string.split('_')[0];
    switch (picturePart) {
        case 'bgImage':
            return 'background';
        case 'leftImage':
            return 'left';
        case 'rightImage':
            return 'right';
        default:
            return null;
    }
}

// TODO: Cover by tests
function getContentPosition(string) {
    const contentPart = string.split('_')[1];
    switch (contentPart) {
        case 'centerContent':
            return 'center';
        case 'leftContent':
            return 'left';
        case 'rightContent':
            return 'right';
        default:
            return null;
    }
}

export default {
    getAdaptedTariffCalculationFormContent,
    getAdaptedHeaderContent,
    getSectionData,
    getContentfulDataByField,
    getContractData,
    getTariffModalConfig,
    getNewsQuotesData
};
